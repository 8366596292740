/*
 * SHRK framework
 * since 1.0.0
 */

/* globals google: false, shrk: true, console: false, skrollr: false */

'use strict';
var shrk = shrk || {};

/* jshint ignore:start */
// Default map style.
shrk.theme_map_style = [{"stylers":[{"visibility":"on"},{"saturation":-100},{"gamma":0.54}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"color":"#4d4946"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"visibility":"simplified"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"simplified"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"gamma":0.48}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"gamma":7.18}]}];
/* jshint ignore:end */

jQuery( document ).ready(function( $ ) {

	/* check if it is a touch enabled device */
	if ( shrk.helpers.is_touch_device() ) {
		$('body').addClass('touch-device');
	}

	$(window).on('shrk_resize', function () {
		shrk.header.manage_resize();
		$( '.page-cover' ).shrk_set_sections();
	});

	shrk.setup.init_page();
});

shrk.setup = {
	init_page: function() {

		shrk.theme_options = shrk.theme_options || {};

		// Reset header height
		shrk.header.reset_height();

		// Bind resize handler
		shrk.setup.bind_events();

		// Misc elements
		shrk.setup.elements();

		// Setup localized strings
		shrk.setup.setTextStrings();

		// Setup some js controlled elements
		shrk.setup.responsive_elements();

		// Bind onScroll events
		jQuery(window).scroll( function () {
			shrk.run_scroll_events();
		});

		// Init header, bind events on header elements, icons etc
		shrk.header.setup();

		// Take care of pagesections heights
		jQuery( 'section.section' ).shrk_set_sections();

		// Take care of page cover heights
		jQuery( '.page-cover' ).shrk_set_sections();

		// Setup parallax elements
		shrk.setup.parallax();

		// Setup sidepanels and bind events
		shrk.sidepanels.setup();

		// Setup custom maps
		shrk.setup.maps();

		// Run onScroll events to initialized header styles etc
		shrk.run_scroll_events();
		shrk.header.manage_resize();

		// Lazyload images in vc_rows
		jQuery(document).on( 'vc-full-width-row', function() {
			var $elements = jQuery('[data-vc-full-width="true"]'); // elements is wrong
			shrk.setup.lazyload_images( $elements );
			jQuery(window).trigger('shrk_after_vc-full-width-row');	        // Runs shrk.swipers.updateAll()
			$elements.find( 'section.section' ).shrk_set_sections(); // Redo set_section after changed width for video backgrounds

			// Fix VC full width elements on systems with decimal window width
			// Temporary fix until fixed by VC
			var dpr = window.devicePixelRatio || 1;
			if ( typeof( shrk.vc_disableFWDPRfix ) === 'undefined' && ( dpr % 1 !== 0 ) ) {
				$elements.outerWidth( jQuery('html')[0].getBoundingClientRect().width );
			}
		});

		jQuery( document ).ready(function( $ ) {
			$('body').addClass('shrk-ready');
			$(window).trigger('shrk_init_page');
		});
	},

	bind_events: function() {
		var $ = jQuery;

		// Setup actions on resize
		$(window).resize(function() {
			shrk_waitForFinalEvent(function() {
				$(window).trigger('shrk_resize');
			}, 200, 'shrkevent');
			shrk.setup.responsive_elements();
		});

		// Scroll to top button
		jQuery('#scroll-to-top').on('click', function () {
			jQuery('body,html').animate({scrollTop: 0}, 800, 'shrk_easeInOutQuart' );
			return false;
		});

		// Scroll to next row button
		jQuery('.scroll-to-next-row a').on('click', function () {
			var $parent_row = jQuery('.scroll-to-next-row a').parents('.vc_row').last();
			var scrollTo    = $parent_row.scrollTop() + $parent_row.outerHeight();
			jQuery('body,html').animate({scrollTop: scrollTo}, 800, 'shrk_easeInOutQuart' );
			return false;
		});

		$(window).on('shrk_async_fonts_active', function () {
			shrk.debug__log('Async fonts loaded.');
			shrk.setup.slabtext();
			shrk.header.manage_resize();
		});

		$(window).on('shrk_async_fonts_inactive', function () {
			shrk.debug__log('Async fonts failed to load.');
		});

		$(window).on('shrk_lazyloaded_bg', function (e, bg_elem) {
			if ( jQuery(bg_elem).length ) {
				jQuery(bg_elem).closest('.page-cover').addClass('page-cover-ready');
			}
		});

		// disable nav links with .nolink class.
		$('.nav .nolink > a').on('click', function () { return false; });
	},

	responsive_elements: function() {
		var $ = jQuery;
		$('.shrk-force-full-width').each( function () {
			var $el = $( this );
			if ( $el.next('.shrk-full-width-row').length === 0 ) {
				$el.after('<div class="shrk-full-width-row"></div>');
			}
			var $el_full = $el.next('.shrk-full-width-row');
			var el_margin_left = parseInt( $el.css( 'margin-left' ), 10 );
			var offset = 0 - $el_full.offset().left - el_margin_left;

			var dpr = window.devicePixelRatio || 1;
			var windowWidth = $( window ).width();

			if ( typeof( shrk.vc_disableFWDPRfix ) === 'undefined' && ( dpr % 1 !== 0 ) ) {
				windowWidth = jQuery('html')[0].getBoundingClientRect().width;
			}

			$el.css( {
				'position': 'relative',
				'left': offset,
				'box-sizing': 'border-box',
				'width': windowWidth
			} );
		});
	},

	parallax: function() {
		var $ = jQuery;
		var disable_parallax = false;

		if ( shrk.theme_options.no_mobile_parallax === '1' && $(window).width() < 992 ) {
			disable_parallax = true;
		}

		if ( typeof($.stellar) !== 'undefined' && !disable_parallax ) {
			//initialize parallax elements
			$.stellar({
				horizontalScrolling: false
			});
		} else {
			$('body').addClass('no-parallax');
		}

		// Disable VC parallax
		if ( disable_parallax ) {
			$(document).on('vc-full-width-row', function () {
				$('[data-vc-parallax]').each( function () {
					if ( $(this).data('vcParallaxImage') ) {
						$(this).css({
							backgroundImage: 'url(' + $(this).data('vcParallaxImage') + ')',
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						});
					}
				});
				$('.vc_parallax-inner').remove();
				$('[data-vc-parallax]').removeClass('vc_parallax vc_parallax-content-moving-fade js-vc_parallax-o-fade');
				$('[data-5p-top-bottom]').removeAttr('data-5p-top-bottom data-30p-top-bottom');
				$('[data-vc-parallax]').removeAttr('data-vc-parallax-o-fade data-vc-parallax-image data-vc-parallax');
			});

			$(document).on('vc_js', function () {
				setTimeout( function () {
					if ( typeof( skrollr ) !== 'undefined' ) {
						skrollr.init().destroy();
					}
				}, 1000); // VC sets parallax at 500ms after vc_js
			});
		} else {
			$(window).on( 'shrk_swipers_init', function() {
				// Refresh parallax positions after swipers init, as the height of the swiper elements is changed.
				if ( typeof( window.vcParallaxSkroll ) !== 'undefined' && window.vcParallaxSkroll ) {
					window.vcParallaxSkroll.refresh();
				}
			});
		}
	},

	maps: function() {
		var $ = jQuery;
		//Styled google maps
		$('.shrk-styled-map-wrapper .shrk-styled-map').each( function () {

			if ( typeof(google) === 'undefined' ) {
				return false;
			}

			var map;
			var map_coord = new google.maps.LatLng(47.376848,8.535347); //default
			var marker_icon;

			var mapOptions = {
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				zoom: 14,
				center: map_coord,
				scrollwheel: false
			};

			if ( $(this).attr('data-map-coord') ) {
				var map_coord_arr = $(this).attr('data-map-coord').split(',');
				map_coord = new google.maps.LatLng( parseFloat(map_coord_arr[0]), parseFloat(map_coord_arr[1]) );
				mapOptions.center = map_coord;
			}

			if ( $(this).attr('data-map-mapstyle') === 'theme' && typeof(shrk.theme_map_style) !== 'undefined' ) {
				mapOptions.styles = shrk.theme_map_style;
			} else if ( $(this).attr('data-map-mapstyle') === 'custom' && typeof(shrk.theme_custom_map_style) !== 'undefined' ) {
				mapOptions.styles = shrk.theme_custom_map_style;
			}


			if ( $(this).attr('data-map-zoom') ) { mapOptions.zoom = parseInt($(this).attr('data-map-zoom'), 10); }
			if ( $(this).attr('data-map-type') ) { mapOptions.mapTypeId = google.maps.MapTypeId[$(this).attr('data-map-type')]; }
			if ( $(this).attr('data-map-scrollwheel') === 'yes' ) { mapOptions.scrollwheel = true; }
			if ( $(this).attr('data-map-marker-icon') ) {
				marker_icon = $(this).attr('data-map-marker-icon');
			} else {
				marker_icon = shrk.theme_options.theme_dir_url+'/img/shrk_marker_light.png';
			}

			//create map
			map = new google.maps.Map( $(this).get(0) , mapOptions);

			//create marker
			var infowindow_html = $(this).parent().find('.infowindow-content').eq(0).html();
			if ( $(this).attr('data-map-marker') === 'yes' ) {
				var marker = new google.maps.Marker({
					position: map_coord,
					map: map,
					title: ( $(this).attr('data-map-infowindowtitle') ? $(this).attr('data-map-infowindowtitle') : null ),
					icon: marker_icon
				});

				//create infowindow
				if ( infowindow_html ) {
					var infowindow = new google.maps.InfoWindow({
						content: infowindow_html
					});
					google.maps.event.addListener(marker, 'click', function() {
						infowindow.open(map,marker);
					});
				}

			}
		});
	},

	elements: function() {
		// Styling to bootstrap
		jQuery( 'input.search-field' ).addClass( 'form-control' );

		// here for each comment reply link of wordpress
		jQuery( '.comment-reply-link' ).addClass( 'btn btn-primary' );

		// here for the submit button of the comment reply form
		jQuery( '#commentsubmit' ).addClass( 'btn btn-primary' );

		// The WordPress Default Widgets
		jQuery( 'input.search-field' ).addClass( 'form-control' );
		jQuery( 'input.search-submit' ).addClass( 'btn btn-default' );
		jQuery( '.widget_rss ul' ).addClass( 'media-list' );
		jQuery( 'table#wp-calendar' ).addClass( 'table');

		jQuery('#nav-below').appendTo('.main-content-bottom-holder');

		// WP 4.8 Video widgets, add class on external provider
		jQuery('.widget_media_video video source[type="video/youtube"]').closest('.wp-video').addClass('external-provider youtube');
		jQuery('.widget_media_video video source[type="video/vimeo"]').closest('.wp-video').addClass('external-provider vimeo');

		shrk.setup.slabtext();
	},

	slabtext: function() {
		if (jQuery.fn.slabText) {
			jQuery('.txt-slab-wrapper .txt-slab').slabText();
			shrk.debug__log('initiated slabText');
		}
	},

	lazyload_images: function( $onElements ) {
		var $ = jQuery;

		if ( typeof( $onElements ) === 'undefined' ) {
			$onElements = $('.lazyload-on-visible');
		} else {
			$onElements = $onElements.find('.lazyload-on-visible');
		}

		$onElements.each( function () {

			if ( $(this).closest('.swiper-slide').length || $(this).closest('.shrk-swiper-container').length ) {
				// It is in a slide, Abort as it is handled by swiper function
				return true;
			}

			// Also need to abort when in vc row = full width
			if ( $(this).closest('[data-vc-full-width-init="false"][data-vc-stretch-content="true"]').length ) {
				// It is in a full width row, Abort as it is handled by vc-full-width-row attached event
				return true;
			}

			if ( $(this).hasClass( 'lazyload-bg' ) && !$(this).hasClass( 'lazyloading-bg' ) && $(this).data('image-bg') ) {
				var offset = $(this).data('appear-offset') || -200;
				if ( ( $(window).scrollTop() + $(window).height() - offset ) > $(this).offset().top ) {
					// Lazyload bg
					var bg = shrk.helpers.get_lazyload_bg( $(this) );

					$(this).css({
						'background-image' : 'url(' + bg + ')'
					});

					if ( typeof( imagesLoaded ) !== 'undefined' ) {
						$(this).addClass( 'lazyloading-bg' );
						$(this).removeClass( 'lazyload-bg' );
						$(this).parent().imagesLoaded({ background: '.lazyloading-bg' }, function () {
							var $bg_elem = $(this.elements).find('.lazyloading-bg');
							$bg_elem.removeClass('lazyload-bg lazyloading-bg');
							$(window).trigger('shrk_lazyloaded_bg', $bg_elem);
							shrk.debug__log('Loaded bg: '+bg);
						});
					} else {
						$(this).removeClass( 'lazyload-bg' );
						$(window).trigger('shrk_lazyloaded_bg', $(this));
						shrk.debug__log('Set bg: '+bg);
					}
				}
			}
		});
	},

	setTextStrings: function () {
		var new_label = shrk.text_strings && shrk.text_strings.new_label ? shrk.text_strings.new_label : 'New';
		jQuery('li.menu-item.new-label > a').append('<span class="new-label">' + new_label + '</span>');
	}
};

shrk.run_scroll_events = function() {
	var $               = jQuery;
	var $header_wrapper = $('#primary-navigation').parent();
	var header_height   = $header_wrapper.height();
	var cur_ScrollTop   = $(window).scrollTop();
	var sc_pos          = 0; //position to show small header
	var safe_offset     = 100;

	if ( $('body').hasClass('head-type-sticky') ) {
		//position after 1st section, or Top + header_height when no 1st section
		sc_pos = header_height + (safe_offset*2);

		if ( cur_ScrollTop >= sc_pos) {
			//time to show small header
			$header_wrapper.addClass('header-scrolled small-head-ready head-sticky head-sticky-visible solid-color');
			//$header_wrapper.children('.site-navigation').css({ height: 'auto' });
			shrk.header.reset_height();

		} else {
			if ( cur_ScrollTop > (header_height+safe_offset) ) {
				//We have scrolled past header
				$header_wrapper.addClass('header-scrolled small-head-ready solid-color');
				$header_wrapper.removeClass('head-sticky-visible');
			} else {
				$header_wrapper.removeClass('header-scrolled small-head-ready head-sticky head-sticky-visible solid-color');
				//$header_wrapper.children('.site-navigation').css({ height: 'auto' });
				shrk.header.reset_height();
			}
		}
	}

	/* show/hide scroll to top button */
	if ( cur_ScrollTop > ( $(window).height() ) ) {
		$('#scroll-to-top').addClass('show');
	} else {
		$('#scroll-to-top').removeClass('show');
	}

	/* set page covers overlay */
	$('.page-cover.has-overlay').each( function () {
		var min, max, opac = 0;
		var bottom_edge = $('.page-cover').offset().top + $('.page-cover').outerHeight();
		var cover_scrll = ( parseInt(cur_ScrollTop, 10) / bottom_edge );
		cover_scrll     = Math.min( Math.max( cover_scrll, 0 ), 1);

		min = typeof( $(this).data('opac-min') ) !== 'undefined' ? $(this).data('opac-min') : 0;
		max = typeof( $(this).data('opac-max') ) !== 'undefined' ? $(this).data('opac-max') : 100;

		if ( min === max ) {
			return; // initial value set in php
		}

		if ( cur_ScrollTop > bottom_edge ) {
			opac = max;
		} else {
			opac = (min + ((max-min)*cover_scrll ));
			opac = Math.round(opac * 100) / 100;
		}

		$(this).find('.cover-overlay').css({ opacity: opac / 100 });
	});

	/* Set opacity of fading header */
	$('.page-cover.fade-header').each( function () {
		var bottom_edge  = $('.page-cover header').offset().top + ( $('.page-cover header').outerHeight() / 2 );
		var header_scrll, header_scrll_pc;
		var div_const   = 4;
		header_scrll    = 1 - ( parseInt( bottom_edge - cur_ScrollTop, 10 ) / ( $(window).height() / div_const ) );
		header_scrll    = Math.min( Math.max( header_scrll, 0 ), 1);
		header_scrll_pc = Math.round(header_scrll * 100);

		$(this).find('header').css({ opacity: ( 1 - ( header_scrll_pc/100 ) ) });
	});

	/* Manage lazyloading of images */
	shrk.setup.lazyload_images();

};

shrk.header = {
	reset_height: function () {
		var $ = jQuery;
		var $wrap = $('header > .site-navigation');
		var $head = $wrap.closest('.site-header');
		var $cont = $wrap.children('.container');
		$wrap.css({ height: $cont.outerHeight() });

		$head.removeClass('mm-fw-visible');
		$wrap.find('li.menu-item.megamenu.fullwidth').addClass('only-js-hover');

		if ( !$head.hasClass('head-sticky') ) {
			$head.removeClass('solid-color');
		}
		jQuery(window).trigger('shrk_header_reset_height');
	},

	manage_resize: function() {
		var $   = jQuery;
		var ovf = false;

		if ( $('.site-navigation-inner').width() < $('.site-navigation-inner').children('.navbar').width() ) { ovf = true; }

		// Take care of overflowing menu items
		$('#main-menu').each( function () {
			var li_w            = 0;
			var $header         = $(this).closest('.site-header');
			var $topbar         = $header.children('.top-bar');
			var $site_nav       = $(this).closest('.site-navigation');
			var $menu_icon      = $site_nav.find('.header-icon.menu-mobile');
			var avl_width_inner = $(this).parent('div').width();
			var avl_width       = $(this).parent('div').outerWidth(); // assuming wrapped in navbar-collapse
			var cont_padding    = avl_width - avl_width_inner;
			var menu_icon_width = $menu_icon.outerWidth();

			if ( !($site_nav.hasClass('menu-collapsed')) ) {
				$(this).children().each( function () {
					li_w = li_w + $(this).width();
				});

				$(this).data('liwidth', li_w);
			} else {
				avl_width = $(this).closest('.navbar').find('.navbar-width-fix').width() + menu_icon_width; // If menu-collapsed navbar-collapse has wrong width. Add menu_icon_width as it is available after expading menu.
			}

			if ( ( typeof(shrk.theme_options) === 'undefined' || shrk.theme_options.autocollapse_menu === '1' ) && ( $(this).data('liwidth') && $(this).data('liwidth') > (avl_width-cont_padding-5) ) ) { // If the option is undefined, the default is true. -5 safety margin
				$site_nav.addClass('menu-collapsed');
				$topbar.css({ 'display': 'none' });

				if ( !($('body').hasClass('transparent-header')) && $(window).width() >= 768 ) {
					$('#main-content').css({ 'padding-top':  $('.site-header').height() });
				}
			} else {
				$site_nav.removeClass('menu-collapsed');
				$topbar.css({ 'display': '' });
				$('#main-content').css({ 'padding-top': '' });
			}
		});

		// Take care of possible overflow of mega menu
		$('.site-navigation .megamenu.autowidth > .sub-menu').each( function () {
			$(this).parent().removeClass('submenu-too-big');
			$(this).css({ 'max-width': '' });
			var initial_width = $(this).outerWidth();

			if ( $(this).isOnScreen( function(deltas) {
				return deltas.top >= this.height() && deltas.bottom >= this.height() && deltas.left >= this.width()	&& deltas.right >= this.width();
			}) === false ) {
				$(this).css({ 'max-width': initial_width });
				$(this).parent().addClass('submenu-too-big');
			}
		});

		$('.top-bar').addClass('temp-fix-block'); // Temporary make top-bar display block to calc menu overflowing text correctly

		// Take care of menu-items that should wrap.
		$('.site-header .sub-menu li > a').not('.site-navigation-inner .megamenu a').each( function () {
			if ( $(this)[0].scrollWidth > $(this).innerWidth() ) {
				$(this).css({ 'width': $(this).innerWidth() });
				$(this).addClass('wraptext');
			}
		});

		$('.top-bar').removeClass('temp-fix-block'); // remove temp class

		// Take care of JS driven horiz menu
		$('.horizontal-menu .widget').find('ul.sub-menu').each( function () {
			$(this).css({ 'display' : 'block', 'visibility' : 'hidden' });
			$(this).data('ulHeight', $(this).outerHeight() );
			$(this).css({ 'display' : 'none', 'visibility' : 'visible' });
		});

		shrk.debug__log('Finished manage_resize');
	},

	setup: function() {
		var $ = jQuery;

		/* Mega menu columns */
		$('.site-navigation .megamenu > ul').each(function() { $(this).addClass('columns-'+$(this).children().length); });

		//Sticky Header
		if ( $('body').hasClass('head-type-sticky') && ($('body').hasClass('head-type-large') || $('body').hasClass('head-type-small')) ) {
			$('#primary-navigation').parent().addClass('head-sticky');
		}

		// mega menu wrapper
		$('header .menu-item.megamenu.fullwidth').hover(
			function () {
				var $wrap = $(this).closest('.site-navigation');
				var $head = $wrap.closest('.site-header');
				var $cont = $wrap.children('.container');
				var $mega = $(this).children('.sub-menu');
				$wrap.css({ height: $cont.outerHeight() + $mega.outerHeight() + 1 });
				$head.addClass('mm-fw-visible solid-color height-transition');
				$(this).removeClass('only-js-hover');
			},
			function () {
				shrk.header.reset_height();
				var $head = $(this).closest('.site-header');
				setTimeout( function () {
					$head.closest('.site-header').removeClass('height-transition');
				}, 300);
			}
		);

		// stick search field open when clicked
		$('header .header-search').on( 'click', '.header-search-button', function (e) {
			$(this).closest('.header-search').addClass('force-show');
			if ( $(e.target).is('a.header-search-button, a.header-search-button i') ) {
				$(this).closest('.header-search').find('input[type="search"]').eq(0).focus();
			}
			e.stopPropagation(); //dont hide it
			return false;
		});

		$('header .header-search input[type="search"]').focus( function () {
			$(this).closest('.header-search').addClass('force-show');
		});

		// hide search field on escape
		$('header .header-search input[type="search"]').on('keyup', function(e) {
			if ( e.keyCode === 27 && $(this).val() === '' ) { // escape key
				$('header .header-search').removeClass('force-show');
			}
		});

		// hide search field on click anywhere
		$('html').on( 'click', function (e) {
			if ( !( $(e.target).is('a.header-search-button, a.header-search-button i, header .header-search input[type="search"]') ) ) {
				$('header .header-search').removeClass('force-show');
			}
		});

		// auto expand submenus in widgets
		$('.widget ul.menu .menu-item-has-children').each( function () {
			var $submenu = $(this).find('ul.sub-menu').eq(0);
			$submenu.hide();
			$(this).hover(
				function () {
					if ( $(this).closest('.horizontal-menu').length ) {
						$(this).css({ 'padding-bottom' : $submenu.data( 'ulHeight' ) });
					}
					$submenu.stop(true, false).slideDown();
					$(this).addClass('submenu-open');
				},
				function () {
					if ( $(this).closest('.horizontal-menu').length ) {
						$(this).css({ 'padding-bottom' : '' });
					}
					$submenu.stop(true, false).slideUp( function () {
						$(this).css({ 'height' : '', 'padding' : '', 'margin' : '' }); // remove inline styles bug.
					});
					$(this).removeClass('submenu-open');
				}
			);
		});
	}
};

shrk.sidepanels = {
	open: function( selector ) {
		if ( jQuery(selector).length ) {
			jQuery(selector).addClass('open');
			jQuery('body').addClass('side-menu-open '+selector.replace('#','')+'-open');
		}
	},

	closeAll: function() {
		jQuery('.side-panel-menu').removeClass('open');
		jQuery('.navbar-toggle').removeClass('open');
		jQuery('body').removeClass('side-menu-open left-side-panel-open right-side-panel-open');
	},

	setup: function() {
		var sp = this;
		jQuery('.navbar-toggle').on('click', function () {
			sp.open('#left-side-panel');
			jQuery(this).addClass('open');
			return false;
		});

		jQuery('.woo-cart-menu-item .woo-cart').on('click', function () {
			if ( jQuery('body').hasClass('woo-cart-dropdown') || ( jQuery('body').hasClass('woo-cart-auto') && jQuery(window).width() > 768 ) ) {
				//return false;
			} else {
				sp.open('#right-side-panel');
				return false;
			}
		});

		// On overlay click close all side-menus
		jQuery('.content-dim-overlay, .side-panel-menu .close-side-panel').on('click', function() {
			sp.closeAll();
		});

		// setup dropdown animations
		jQuery(document).off('click.bs.dropdown.data-api', '[data-toggle="dropdown"]');
		jQuery('.side-panel-menu ul.nav > li.menu-item-has-children > a').on('click', function () {
			jQuery(this).parent().find('ul.sub-menu').slideToggle('fast', function () {
				var $parent = jQuery(this).parent();

				if ( $parent.hasClass('dropdown-open') ) {
					$parent.find('.sub-menu').slideUp(200);
					$parent.removeClass('dropdown-open');
				} else {
					$parent.find('.sub-menu').slideDown(200);
					$parent.addClass('dropdown-open');
				}
			});
			return false;
		});

		// add caret to wpml menu links
		jQuery('.side-panel-menu .menu-item-language-current > a').append(' <span class="caret"></span>');

		// Move yith-search form to side panel in mobile (avoids dup ID in html element)
		if ( jQuery(window).width() < 480 ) {
			jQuery('.search-dropdown > * ').eq(0).detach().appendTo('#sidepanel-search-form-holder');
		}
	}
};

shrk.helpers = {
	is_touch_device: function() {
		return 'ontouchstart' in window || navigator.maxTouchPoints;
	},

	get_lazyload_bg: function( $obj ) {
		// $obj must have a data-srcset attr with the srcset, else data-image-bg is used as a fallback
		var bg, i, src_height;
		var src_set = jQuery( $obj ).data('srcset');
		var src_bg  = jQuery( $obj ).data('image-bg');
		var src_ar  = jQuery( $obj ).data('image-ar');

		if ( typeof( src_set ) === 'undefined' || src_set === '' ) {
			return typeof( src_bg ) !== 'undefined' ? src_bg : '';
		}

		var src_set_arr = src_set.split(', ');

		for (i = 0; i < src_set_arr.length; i++) {
		    src_set_arr[i] = src_set_arr[i].split(' ');

		    // Remove 'w' from width and make it and int.
		    // The value is either in pixels with 'w' as a descriptor (ie '1920w') or,
		    // In pixel density (ie '2x')
		    // In the second case the remaining int is much smaller than any window size in pixels
		    // thus effectively ignored.
		    src_set_arr[i][1] = parseInt( src_set_arr[i][1], 10 );
		}

		src_set_arr.sort(function(a,b){ return a[1] > b[1] ? 1 : -1; }); // Sort array by width ASC

		var dpr          = window.devicePixelRatio || 1;
		var targetWidth  = $obj.outerWidth();
		var targetHeight = $obj.outerHeight();
		var effectWidth  = targetWidth * dpr;
		var effectHeight = targetHeight * dpr;
		var landscape    = targetWidth >= targetHeight ? true : false;

		// Loop Sorted array and find the 1st width that is larger than effectWidth, or the bigger if none found
		for (i = 0; i < src_set_arr.length; i++) {
		    bg = src_set_arr[i][0];

		    if ( landscape && src_set_arr[i][1] >= effectWidth ) {
		    	break;
		    }

		    if ( !landscape ) {
		    	// Portrait mode.
		    	// We need to pick an image taking into account
		    	// the image's height instead of the width.
		    	// This covers the cover case (sic).

		    	if ( typeof( src_ar ) !== 'undefined' ) {
		    		src_height = parseInt(src_set_arr[i][1], 10) / parseFloat(src_ar);
		    	} else {
			    	var regex      = /[_-](\d+x\d+)(?=\.[a-z]{3,4}$)/g;    // Example: big_x_23423423423424342_256x256.jpg
			    	var src_sizes  = regex.exec(bg);                       // Group 1 matches 256x256

			    	if ( src_sizes === null ) {
			    		// No dimensions in file name. Probably Full image.
			    		break;
			    	}

			    	src_height = src_sizes[1];                         // Get matching group 1
			    	src_height = src_height.toLowerCase();             // Lowercase just in case
			    	src_height = src_height.split('x')[1];             // get height
		    	}

		    	if ( src_height >= effectHeight ) {
		    		break;
		    	}
		    }
		}
		return bg || src_bg;
	}
};


jQuery.extend( jQuery.easing, {
	shrk_easeInOutCubic: function(x, t, b, c, d) {
		if ((t/=d/2) < 1) { return c/2*t*t*t + b; }
		return c/2*((t-=2)*t*t + 2) + b;
	},

	shrk_easeInOutQuart: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) { return c/2*t*t*t*t + b; }
        return -c/2 * ((t-=2)*t*t*t - 2) + b;
    }
});

shrk.debug__log = function( str ) {
	if ( typeof(shrk.wp) !== 'undefined' && typeof(shrk.wp.wp_debug) !== 'undefined' && typeof(console) !== 'undefined' ) {
		console.log(str);
	}
};


/*
 * delay function (used in window resize)
 */
 var shrk_waitForFinalEvent = (function() {
	var timers = {};
	return function(callback, ms, uniqueId) {
		if (!uniqueId) {
			uniqueId = 'Don\'t call this twice without a uniqueId';
		}
		if (timers[uniqueId]) {
			clearTimeout(timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
 })();


/**
 * shrk jQuery plugins
 */
(function($) {
	$.fn.shrk_resize_elem = function() {
		//target = element to resize
		//source = element whose dimensions to use

		var settings = arguments[0];

		var $targets = $(this);
		var $source = settings.source;

		$targets.each( function () {

			var $target = $(this);

			if(typeof $source === 'undefined' || $source === 'parent' || settings.source === 'parent') {
				$source = $(this).parent();
			}


			var srcW = parseInt ( $source.css( 'width' ), 10 );
			var srcH = parseInt ( $source.css( 'height' ), 10 );
			var targetW = parseInt ( $target.css( 'width'), 10 );
			var targetH = parseInt ( $target.css( 'height'), 10 );
			var newLeft = 0;
			var newTop 	= 0;

			//var srcRatio = (srcW / srcH);
			var targetRatio = (targetW / targetH);

			//Set size to source width + height overflows
			targetW = srcW;
			targetH = targetW * ( 1 / targetRatio);

			//If height doesnt overflow, set size to source height + width overflows
			if ( targetH < srcH ) {
				targetH = srcH;
				targetW = targetH * targetRatio;
			}

			//Calculate position:
			if ( settings.position_h === 'left' ) {
				newLeft = 0;
			} else if ( settings.position_h === 'middle' ) {
				newLeft = -((targetW - srcW) / 2);	//horizontal middle
			}

			if ( settings.position_v === 'top' ) {
				newTop = 0;
			} else if ( settings.position_v === 'middle' ) {
				newTop  = -((targetH - srcH) / 2); //vertical middle
			}

			//Resize element
			$target.css({
				'width' : targetW,
				'height': targetH,
				'left'	: newLeft,
				'top'	: newTop
			});

		});
	return this;
	};

	$.fn.shrk_resize_elem.defaults = {
		position_h	: 'middle',
		position_v	: 'middle',
		source		: 'parent'
	};
})( jQuery );


/**
 * shrk_set_sections
 * required for pagesections and pagecover elements
 *
 * Affects pagesections height and video background width
 */
(function($) {
	$.fn.shrk_set_sections = function() {

		var $targets = $(this);

		$targets.each( function () {

			var $target = $(this);
			var new_height = false;

			if ( $target.hasClass('windowheight') ) {
				new_height = $( window ).height() - $( '#wpadminbar' ).height();
			} else if ( $target.hasClass('customheight') && $target.data('section-height') ) {
				var sh = $target.data('section-height');
				if ( sh.trim().slice(-1) === '%' ) {
					//we got a percentage
					var m = ( parseInt( sh, 10 ) / 100 );
					new_height = parseInt( $( window ).height() * m, 10 );
				} else {
					//we are assuming we got a css value
					new_height = sh.trim();
				}
			}

			if ( $target.hasClass('minus-menu') ) {
				new_height = parseInt( new_height - $('#primary-navigation').height(), 10 );
			}

			if ( $target.hasClass('minus-html-padding') ) {
				var wPadding = $('html').css([ 'padding-top', 'padding-bottom' ]);
				new_height = parseInt( new_height - parseInt(wPadding['padding-top'], 10) - parseInt(wPadding['padding-bottom'], 10), 10 );
			}

			if ( $target.hasClass('minus-body-border') ) {
				var wBorder = $('body').css([ 'border-top-width', 'border-bottom-width' ]);
				new_height = parseInt( new_height - parseInt(wBorder['border-top-width'], 10) - parseInt(wBorder['border-bottom-width'], 10), 10 );
			}

			if ( new_height !== false ) {
				$target.css( 'height', new_height );
			}

			$target.find('.section-background.video video').shrk_resize_elem({
				'source':     'parent',
				'position_v': 'middle',
				'position_h': 'middle'
			});

		});

		$(window).trigger('shrk_sections_init');
		return this;
	};

	$.fn.shrk_set_sections.defaults = {

	};
})( jQuery );

/**
 * skip-link-focus-fix.js
 */
( function() {
	var is_webkit = navigator.userAgent.toLowerCase().indexOf( 'webkit' ) > -1,
	    is_opera  = navigator.userAgent.toLowerCase().indexOf( 'opera' )  > -1,
	    is_ie     = navigator.userAgent.toLowerCase().indexOf( 'msie' )   > -1;

	if ( ( is_webkit || is_opera || is_ie ) && 'undefined' !== typeof( document.getElementById ) ) {
		var eventMethod = ( window.addEventListener ) ? 'addEventListener' : 'attachEvent';
		window[ eventMethod ]( 'hashchange', function() {
			var element = document.getElementById( location.hash.substring( 1 ) );

			if ( element ) {
				if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) {
					element.tabIndex = -1;
				}

				element.focus();
			}
		}, false );
	}
})();

/**
 * jQuery.isOnScreen
 * https://github.com/moagrius/isOnScreen
 * Author: https://github.com/moagrius
 */
(function ($) {

/**
* Tests if a node is positioned within the current viewport.
* It does not test any other type of "visibility", like css display,
* opacity, presence in the dom, etc - it only considers position.
*
* By default, it tests if at least 1 pixel is showing, regardless of
* orientation - however an optional argument is accepted, a callback
* that is passed the number of pixels distant between each edge of the
* node and the corresponding viewport.  If the callback argument is provided
* the return value (true of false) of that callback is used instead.
*/
$.fn.isOnScreen = function(test){

	var height = this.outerHeight();
	var width = this.outerWidth();

	if(!width || !height){
		return false;
	}

	var win = $(window);

	var viewport = {
		top : win.scrollTop(),
		left : win.scrollLeft()
	};
	viewport.right = viewport.left + win.width();
	viewport.bottom = viewport.top + win.height();

	var bounds = this.offset();
	bounds.right = bounds.left + width;
	bounds.bottom = bounds.top + height;

	var deltas = {
		top : viewport.bottom - bounds.top,
		left: viewport.right - bounds.left,
		bottom: bounds.bottom - viewport.top,
		right: bounds.right - viewport.left
	};

	if(typeof test === 'function') {
		return test.call(this, deltas);
	}

	return deltas.top > 0 && deltas.left > 0 && deltas.right > 0 && deltas.bottom > 0;
};

})(jQuery);